<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full mb-base">
      <vx-card title="Upload Bukti Penerimaan Barang">
        <!-- FORM START -->
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="vx-col w-full mb-base">
              <input
                type="file"
                id="file"
                ref="file"
                @change="handleFileUpload()"
                multiple
              />
            </div>
            <!-- <div class="vx-col w-full">
              <div class="vx-col w-full mb-base">
                <a href="/template_import_job_order.xlsx" download
                  >Download template job order</a
                >
              </div>
            </div> -->
          </div>
        </div>
        <div class="flex">
          <vs-button
            @click="submitFile()"
            :disabled="disBtn"
            class="mr-1 ml-1"
            color="primary"
            icon-pack="feather"
            icon="icon-save"
            >Upload</vs-button
          >&nbsp;
          <vs-button
            class="mr-1 ml-1"
            @click="$router.back()"
            type="border"
            color="warning"
            >Back</vs-button
          >
          <slot name="footer"></slot>
        </div>
        <!-- FORM END -->
      </vx-card>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";

export default {
  data() {
    return {
      disBtn : false,
      file: "",
    };
  },

  methods: {
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      let self = this;
      this.disBtn = true
      axios
        .post("operational/delivery_orders/"+this.$route.params.id+"/file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
            self.disBtn = false
            self.$vs.notify({
                title: `OK`,
                text: `Data successfully uploaded`,
                color: `success`
            });
            self.$router.back()
        })
        .catch(function (err) {
            self.disBtn = false
            // console.log(err)
          let error = err.response;
          self.$vs.notify({
            title: "Oops!",
            text: error.data.message,
            color: "danger",
          });
        });
      // self.disBtn = false
      // self.$vs.notify({
      //     title: `OK`,
      //     text: `Data successfully uploaded`,
      //     color: `success`
      // });
      // self.$router.back()
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>